<template>
    <div>
        <v-container grid-list-md text-xs-center fluid>
            <h1>Despesas</h1>
            <v-divider class="mb-4"></v-divider>

            <FilterDateClientStore hideClient @changeDate="changeDate" @changeStore="changeStore" />

            <v-layout wrap class="my-12" v-if="items.length > 0">
                <v-flex xs12>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                R$ {{ total }}
                            </div>
                            Total
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm4>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>attach_money</v-icon>
                                R$ {{ money }}
                            </div>
                            Dinheiro
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm4>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>pix</v-icon>
                                R$ {{ pix }}
                            </div>
                            PIX
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm4>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>credit_card</v-icon>
                                R$ {{ card }}
                            </div>
                            Cartão débito e crédito
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12>
                    <v-card color="error" v-if="canceled > 0">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                R$ {{ canceled }}
                            </div>
                            Cancelados
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>

        <SimpleTable v-if="items.length > 0" formclass="Expense/form" :items="items" @needReload="search" endpoint="expenses" :aditionalColumns="headers" inlineToggle hideDelete
            hideNew hideIdColumn>
            <template v-slot:extra-column="item">
                <v-chip class="ma-0" outlined :color="item.enable ? 'success' : 'error'"> R$ {{ item.value }} ({{ item.detail }}) </v-chip>
                <v-chip class="ma-0" outlined :color="item.enable ? 'success' : 'error'" v-if="item.createdAt"> {{ $moment(item.createdAt).format("DD/MM/YYYY") }} </v-chip>
                <v-chip class="ma-0" outlined color="error" v-if="!item.enable"> Cancelado </v-chip>
            </template>
        </SimpleTable>
    </div>
</template>

<script>
import FilterDateClientStore from "@/components/shared/FilterDateClientStore";
import SimpleTable from "@/components/core/SimpleTable";

export default {
    components: { FilterDateClientStore, SimpleTable },

    data() {
        return {
            dialog: false,
            items: [],
            dates: [],
            selectedStore: null,
            headers: [
                { text: this.$t("Habilitado"), value: "enable", align: "right", sortable: false },
                { text: this.$t("Data"), value: "extra-column", align: "right", sortable: true },
                { text: this.$t("Recebido por"), value: "audit.createdBy", align: "right", sortable: true },
                { text: this.$t("Loja"), value: "store", align: "right", sortable: true },
                // { text: this.$t("Atendimento"), value: "service._id", align: "right", sortable: true },
            ],
        };
    },

    computed: {
        total() {
            return Number.parseFloat(this.items.reduce((sum, i) => (i.enable ? sum + parseFloat(i.value) : sum), 0)).toFixed(2);
        },
        pix() {
            return Number.parseFloat(this.items.reduce((sum, i) => (i.enable && i.method == "PIX" ? sum + parseFloat(i.value) : sum), 0)).toFixed(2);
        },
        money() {
            return Number.parseFloat(this.items.reduce((sum, i) => (i.enable && i.method == "Dinheiro" ? sum + parseFloat(i.value) : sum), 0)).toFixed(2);
        },
        card() {
            return Number.parseFloat(
                this.items.reduce((sum, i) => ((i.enable && i.method == "Cartão Crédito") || i.method == "Cartão Débito" ? sum + parseFloat(i.value) : sum), 0)
            ).toFixed(2);
        },
        canceled() {
            return Number.parseFloat(this.items.reduce((sum, i) => (i.enable == false ? sum + parseFloat(i.value) : sum), 0)).toFixed(2);
        },
    },

    methods: {
        changeStore(event) {
            this.selectedStore = event;
            this.search();
        },
        changeDate(event) {
            this.dates = event;
            this.search();
        },

        search() {
            let query = this.dates.length == 2 ? `?from=${this.dates[0]}&to=${this.dates[1]}` : `?from=${this.dates[0]}&to=${this.dates[0]}`;

            if (this.selectedStore) {
                query += `&store=${this.selectedStore.id}`;
            }

            this.$http
                .get(`expenses${query}&timezone=-3`)
                .then((result) => (this.items = result))
                .catch(() => (this.items = []));
        },
    },
};
</script>